import React from "react";
import { Route, useHistory } from "react-router-dom";
import { func, bool, object, oneOfType, string } from "prop-types";
import styled from "styled-components";
import LoadingSpinner from "../components/NewLoadingSpinner";
import { getMeAdmin } from "../services/apiRouterService";
import { useQuery } from "react-query";

const PrivateRouteHoc = ({
  component: Component,
  noSocial,
  noTrial,
  path,
  ...rest
}) => {
  const history = useHistory();
  const { isLoading, data } = useQuery("/admin/me", getMeAdmin);

  if (isLoading) return <Route {...rest} render={() => <FullpageLoading />} />;
  else if (data)
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  else {
    history.push(`/admin/login`);
    return <Route {...rest} render={() => <FullpageLoading />} />;
  }
};

const FullpageLoading = () => (
  <LoadingContainer>
    <LoadingSpinner size="100" />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

PrivateRouteHoc.defaultProps = {
  noSocial: false,
  noTrial: false,
};

PrivateRouteHoc.propTypes = {
  component: oneOfType([func.isRequired, object.isRequired]),
  noSocial: bool,
  noTrial: bool,
  path: string,
};

export default PrivateRouteHoc;
