import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Header from "../components/Header";

const PunchClockComponent = ({
  constructionData,
  checkEmployeeHandler,
  // checking,
  // error,
  actionDone,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header>{t("Punch clock")}</Header>
      <Logo src={constructionData?.company?.company_logo} />
      <Warnings>
        {!constructionData.company.company_active && (
          <Warning>{t("The company has been deactivated")}</Warning>
        )}
        {!constructionData?.construction_site?.construction_site_active && (
          <Warning>{t("The construction site has been deactivated")}</Warning>
        )}
        {!constructionData.employee.employee_active && (
          <Warning>{t("Your account has been deactivated")}</Warning>
        )}
      </Warnings>
      <Row>
        <Label>{t("Construction site")}:</Label>
        <p>{constructionData?.construction_site?.construction_site_name}</p>
      </Row>
      <Row>
        <Label>{t("Company")}:</Label>
        <p>{constructionData?.company?.company_name}</p>
      </Row>
      <Row>
        <Label>{t("Employee")}:</Label>
        <p>
          {constructionData?.employee?.employee_fname}{" "}
          {constructionData?.employee?.employee_lname}
        </p>
      </Row>
      {actionDone || actionDone === 0 ? (
        {
          0: <p>{t("You have been checked in you can close this window")}</p>,
          1: <p>{t("You have been checked out you can close this window")}</p>,
        }[actionDone]
      ) : (
        <ButtonContainer>
          <button
            disabled={
              !constructionData.employee.employee_active ||
              !constructionData?.construction_site.construction_site_active ||
              !constructionData?.company?.company_active
            }
            onClick={(e) => checkEmployeeHandler(e, 0)}
          >
            {t("Check in")}
          </button>
          <button
            disabled={
              !constructionData.employee.employee_active ||
              !constructionData?.construction_site.construction_site_active ||
              !constructionData?.company?.company_active
            }
            onClick={(e) => checkEmployeeHandler(e, 1)}
          >
            {t("Check out")}
          </button>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Warnings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Warning = styled.div`
  background-color: #fff0a4;
  border: 2px solid #ecc602;
  border-radius: 1rem;
  font-weight: bold;
  padding: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem;
  font-size: 1.5rem;
  padding-top: 6.5rem;
  line-height: 1.5;
`;

const Label = styled.h2`
  font-weight: bold;
`;

const Row = styled.div`
  margin-bottom: 2rem;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 50rem;
  object-fit: contain;
  padding: 2rem;
  margin: auto;
  margin-bottom: 2rem;
  background-color: #082234;
  border-radius: 1rem;
`;

const ButtonContainer = styled.div`
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  & > button {
    padding: 2rem;
  }
`;

export default PunchClockComponent;
