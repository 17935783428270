import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateSessionRouteHoc from "../components/PrivateSessionRouteHoc";

import PunchClock from "../pages/PunchClock";
import Home from "../pages/Home";
import EmployeeAuthenticator from "../pages/EmployeeAuthenticator";
import AdminPanelRouter from "./AdminPanelRouter";

const GlobalRouter = () => (
  <Router>
    <Switch>
      <Route
        path="/"
        component={Home}
        exact
      />
      <PrivateSessionRouteHoc
        path="/punch-clock/:construction_site_uuid"
        component={PunchClock}
        to="/"
      />
      <Route
        path="/authenticate-employee/:employee_uuid"
        component={EmployeeAuthenticator}
      />
      <Route path="/admin" component={AdminPanelRouter} />
    </Switch>
  </Router>
);

export default GlobalRouter;
