import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useHistory } from "react-router";
import styled from "styled-components";

import TextInput from "../../components/TextInput";
import { adminLogin } from "../../services/apiRouterService";
import { useState } from "react";
import { useQueryClient } from "react-query";

const Login = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const schema = object().shape({
    email: string().required("Required"),
    password: string().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitLogin = async (values) => {
    const { response, status } = await adminLogin(values);
    if (status === 200) {
      queryClient.clear();
      localStorage.setItem("access-token", response?.access_token);
      setTimeout(() => {
        history.push("/admin");
      }, 500);
    } else {
      return setError("Wrong username or password");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitLogin)}>
        <h1>Sign in</h1>
        <TextInput
          type="email"
          error={errors["email"]?.message}
          {...register("email", { required: true })}
        >
          Email
        </TextInput>
        <TextInput
          type="password"
          error={errors["password"]?.message}
          {...register("password", { required: true })}
        >
          Password
        </TextInput>
        {error && <Error>{error}</Error>}
        <input type="submit" />
      </Form>
    </Container>
  );
};

const Error = styled.p`
  color: red;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
`;

const Form = styled.form`
  max-width: 50rem;
  width: 100%;
  padding: 4rem;
  margin: auto;

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
`;

export default Login;
