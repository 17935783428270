import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
const Header = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Container>
      <BackButton onClick={e => {
        e.preventDefault();
        history.push('/');
      }}>
        &lt; {t('Go back')}
      </BackButton>
      <Title>
        {children}
      </Title>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  height: 5rem;
  background-color: #ececec;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1rem rgba(0,0,0,.5);
  right: 0;
  top: 0;
  margin-bottom: 2rem;
`;

const BackButton = styled.button`
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  bottom: 0;
  padding: 1rem;
`;


const Title = styled.h1`
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  font-size: 2.4rem;
  color: #082234;
`;

export default Header;
