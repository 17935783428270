import React from "react";
import { Route } from "react-router-dom";
import { func, string } from "prop-types";
import { useQuery } from "react-query";
import styled from "styled-components";

// Services
import { verifyAuthenticatedEmployee } from "../services/apiRouterService";

// Components
import LoadingSpinner from "./LoadingSpinner";
import ErrorComponent from "./ErrorComponent";

const PrivateSessionRouteHoc = ({ component: Component, to, ...rest }) => {
  const { isLoading, data } = useQuery(
    ["authenticateEmployee"],
    verifyAuthenticatedEmployee
  );

  // console.log(data, isLoading, isSuccess, errors, employeeData);

  if (isLoading)
    return (
      <LoadingContainer>
        <LoadingSpinner isAbsolute />
      </LoadingContainer>
    );
  if (data?.status !== 200)
    return (
      <Route
        {...rest}
        render={(props) => (
          <ErrorComponent type="EMPLOYEE" errors={data} {...props} />
        )}
      />
    );
  return (
    <Route
      {...rest}
      render={(props) => <Component employeeData={data.response} {...props} />}
    />
  );
};

PrivateSessionRouteHoc.propTypes = {
  component: func.isRequired,
  to: string.isRequired,
};

const LoadingContainer = styled.div`
  width: 100%;
`;

export default PrivateSessionRouteHoc;
