import { useHistory, useParams } from "react-router";
import { useQuery } from "react-query";
import {
  checkEmployee,
  getConstructionSiteInformation,
} from "../services/apiRouterService";
import styled from "styled-components";

// Components
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorComponent from "../components/ErrorComponent";
import PunchClockComponent from "../components/PunchClockComponent";
import { useState } from "react";

const PunchClock = ({ employeeData }) => {
  const history = useHistory();
  const { construction_site_uuid } = useParams();
  const [actionDone, setActionDone] = useState();
  const [error, setError] = useState();
  const [checking, setChecking] = useState(true);
  const { isLoading, data } = useQuery(
    [employeeData.employee_uuid, construction_site_uuid],
    () => getConstructionSiteInformation(construction_site_uuid)
  );

  const checkEmployeeHandler = async (e, type) => {
    if (e) e?.preventDefault();
    setChecking(true);
    setActionDone();
    const { response, status } = await checkEmployee(type, {
      coordinates: "",
      construction_site:
        data?.response?.construction_site?.construction_site_uuid,
    });
    if (status === 201) {
      setActionDone(+type);
      setTimeout(() => {
        history.push("/");
      }, 3000);
    } else {
      setError(response);
    }
    setChecking(false);
    console.log(response, status);
  };

  if (isLoading)
    return (
      <LoadingContainer>
        <LoadingSpinner isAbsolute />
      </LoadingContainer>
    );

  if (data?.status !== 200)
    return <ErrorComponent type="PUNCH_CLOCK" errors={data} />;

  return (
    <PunchClockComponent
      constructionData={data?.response}
      checkEmployeeHandler={checkEmployeeHandler}
      checking={checking}
      actionDone={actionDone}
      error={error}
    />
  );
};

const LoadingContainer = styled.div`
  width: 100%;
`;

export default PunchClock;
