import { useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import styled from "styled-components";
import { useQuery } from "react-query";

import LoadingSpinner from "../components/LoadingSpinner";

import { verifyAuthenticatedEmployee } from "../services/apiRouterService";

const Home = () => {
  const { t } = useTranslation();
  const [found, setFound] = useState(false);
  const { isLoading, data } = useQuery(
    "/employee",
    verifyAuthenticatedEmployee
  );

  const handleScan = (data) => {
    if (data && !found) {
      setFound(true);
      window.location.href = data;
    }
  };

  const handleError = (item) => {
    console.log(item);
  };

  return (
    <Container>
      <Head>
        {isLoading ? (
          <SpinnerContainer>
            <LoadingSpinner size={30} />
          </SpinnerContainer>
        ) : data?.status !== 200 ? (
          t("You have not been authenticated yet")
        ) : (
          `${t("Welcome back")}, ${data?.response?.employee_fname} ${
            data?.response?.employee_lname
          }`
        )}
      </Head>
      <Scanner>
        <Box />
        <StyledQrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          facingMode="environment"
          style={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />
      </Scanner>
    </Container>
  );
};

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    margin: auto;
    position: static;
  }
`;

const Head = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  background-color: white;
  z-index: 21;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
`;

const Box = styled.div`
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
  border: 4px solid #2b547e;
  width: 80%;
  height: 60%;
  z-index: 20;
`;

const Scanner = styled.div`
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  display: flex;
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
`;

const StyledQrReader = styled(QrReader)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  & > section {
    height: 100vh;

    & div {
      display: none;
    }
  }
`;

export default Home;
