const buildAuthHeader = () => {
  const accessToken = localStorage.getItem("access-token");
  if (accessToken)
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  return {};
};

export async function request(path, method, payload, customHeaders) {
  let req;
  const headers = buildAuthHeader();
  if (payload == null) {
    req = await fetch(`${path}`, {
      method,
      credentials: "include",
      headers,
    });
  } else {
    req = await fetch(`${path}`, {
      method,
      credentials: "include",
      headers,
      body: payload instanceof FormData ? payload : JSON.stringify(payload),
    });
  }

  return new Promise(async (resolve) => {
    const contentType = req.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      resolve({ response: await req.json(), status: req.status });
    } else {
      resolve({ response: await req, status: req.status });
    }
  });
}
