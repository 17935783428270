import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import QRCode from "qrcode";
import EmployeeContainer from "../../components/EmployeeContainer";
import ConstructionSiteContainer from "../../components/ConstructionSiteContainer";
import {
  getEmployeesAdmin,
  adminAddEmployee,
  getConstructionSitesAdmin,
  adminAddConstructionSite,
} from "../../services/apiRouterService";
import TextInput from "../../components/TextInput";

const Admin = () => {
  const history = useHistory();
  const [dates, setDates] = useState();
  const [qrCodes, setQrCodes] = useState({
    employees: {},
    constructionSites: {},
  });

  const schema = object().shape({
    first_name: string().required("Required"),
    last_name: string().required("Required"),
  });

  const schemaCS = object().shape({
    name: string().required("Required"),
  });

  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,
    reset: resetFilter,
    getValues,
  } = useForm();

  const { isLoading, data, refetch, status } = useQuery(
    "/admin/employees",
    () => getEmployeesAdmin(getValues())
  );

  const {
    data: constructionSiteData,
    isLoading: isLoadingConstructionSite,
    refetch: refetchConstructionSite,
  } = useQuery("/admin/construction-sites", () =>
    getConstructionSitesAdmin(getValues())
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerCS,
    handleSubmit: handleSubmitCS,
    reset: resetCS,
    formState: { errors: errorsCS },
  } = useForm({
    resolver: yupResolver(schemaCS),
  });

  const submitAddEmployee = async (values) => {
    const { status } = await adminAddEmployee(values);
    if (status === 201) {
      reset();
      refetch();
    }
  };

  const submitAddConstructionSite = async (values) => {
    const { status } = await adminAddConstructionSite(values);
    if (status === 201) {
      resetCS();
      refetchConstructionSite();
    }
  };

  const generateQRCodeImage = async (type, uuid) => {
    const url = "https://amant.dev";
    const paths = {
      employees: "/authenticate-employee/",
      constructionSites: "/punch-clock/",
    };
    const qrcode = await QRCode.toDataURL(`${url}${paths[type]}${uuid}`);
    setQrCodes((prevValue) => ({
      ...prevValue,
      [type]: {
        ...prevValue[type],
        [uuid]: qrcode,
      },
    }));
    return <img src={qrcode} alt="qr-code" />;
  };

  useEffect(() => {
    if (!!data?.response?.length)
      data?.response?.forEach((employee) => {
        generateQRCodeImage("employees", employee?.employee_uuid);
      });
  }, [data]);

  useEffect(() => {
    if (!!constructionSiteData?.response?.length)
      constructionSiteData?.response?.forEach((constructionSite) => {
        generateQRCodeImage(
          "constructionSites",
          constructionSite?.construction_site_uuid
        );
      });
  }, [constructionSiteData]);

  useEffect(() => {
    if (status === "success") {
      if (data?.status === 401 || data?.status === 403) {
        history.push("/admin/login");
      }
    }
  }, [status, data?.status, history, isLoading]);

  const refetchWithFiltering = () => {
    refetch();
    refetchConstructionSite();
    setDates(getValues());
  };

  if (isLoading) {
    return <p>Loading</p>;
  }
  return (
    <Container>
      <Title>Admin page</Title>
      <div>
        <h3>Filters</h3>
        <form
          onSubmit={handleSubmitFilter(refetchWithFiltering)}
          onReset={() => {
            resetFilter();
            refetch();
            refetchConstructionSite();
            setDates();
          }}
        >
          <label htmlFor="start">Start datum</label>
          <br />
          <input
            type="date"
            id="start"
            {...registerFilter("start", { required: false })}
          />
          <br />
          <br />
          <label htmlFor="end">Eind datum</label>
          <br />
          <input
            type="date"
            id="end"
            {...registerFilter("end", { required: false })}
          />
          <br />
          <br />
          <input type="submit" name="submit" />
          <input type="reset" name="reset" />
          <br />
          <br />
          <br />
        </form>
      </div>
      {data?.status >= 200 && data?.status < 300 ? (
        <EmployeeContainer
          refetch={refetch}
          qrCodes={qrCodes}
          isLoading={isLoading}
          dates={dates}
          employees={data?.response}
        />
      ) : (
        <p>No employees yet</p>
      )}
      <Form onSubmit={handleSubmit(submitAddEmployee)}>
        <h1>Add employee</h1>
        <TextInput
          type="first_name"
          error={errors["first_name"]?.message}
          {...register("first_name", { required: true })}
        >
          First name
        </TextInput>
        <TextInput
          type="last_name"
          error={errors["last_name"]?.message}
          {...register("last_name", { required: true })}
        >
          Last name
        </TextInput>
        <input type="submit" />
      </Form>
      <hr></hr>
      <ConstructionSitesContainer>
        {constructionSiteData?.status >= 200 &&
        constructionSiteData?.status < 300 ? (
          <ConstructionSiteContainer
            refetch={refetchConstructionSite}
            qrCodes={qrCodes}
            dates={dates}
            isLoading={isLoadingConstructionSite}
            constructionSites={constructionSiteData?.response}
          />
        ) : (
          /* <ConstructionDetail key={key}>
              <button
                onClick={(e) =>
                  deleteConstructionSite(e, item?.construction_site_uuid)
                }
              >
                Delete
              </button>
            </ConstructionDetail> */
          <p>No construction sites yet</p>
        )}
      </ConstructionSitesContainer>
      <Form onSubmit={handleSubmitCS(submitAddConstructionSite)}>
        <h1>Add construction site</h1>
        <TextInput
          type="name"
          error={errorsCS["name"]?.message}
          {...registerCS("name", { required: true })}
        >
          Construction site name
        </TextInput>
        <input type="submit" />
      </Form>
    </Container>
  );
};

const ConstructionSitesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 4rem;
`;

const Form = styled.form`
  max-width: 50rem;
  width: 100%;
  padding: 4rem;
  margin: auto;

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }
`;

export default Admin;
