import styled from "styled-components";
import LoadingSpinner from "../components/LoadingSpinner";
import ConstructionSiteDetail from "../components/ConstructionSiteDetail";
import cogoToast from "cogo-toast";
import { exportConstructionSitesToExcel } from "../services/apiRouterService";

const ConstructionSiteContainer = ({
  isLoading,
  constructionSites,
  refetch,
  qrCodes,
  dates,
}) => {
  const exportToExcel = async (e) => {
    e.preventDefault();
    try {
      exportConstructionSitesToExcel(dates);
    } catch {
      cogoToast.error("Something went wrong ");
    }
  };
  return (
    <Container>
      <Header>
        <h2>Construction sites container</h2>
        <Button onClick={exportToExcel}>Export to excel</Button>
      </Header>
      <Employees>
        {isLoading ? (
          <SpinnerContainer>
            <LoadingSpinner size={30} />
          </SpinnerContainer>
        ) : (
          constructionSites?.map((constructionSite) => (
            <ConstructionSiteDetail
              refetch={refetch}
              qrCodes={qrCodes}
              dates={dates}
              key={constructionSite?.construction_site_id}
              constructionSite={constructionSite}
            />
          ))
        )}
      </Employees>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 2rem 1rem;
  margin-left: 2rem;
`;

const Employees = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin: -1rem;
  & > div {
    margin: 1rem;
    width: 100%;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    margin: auto;
    position: static;
  }
`;

const Container = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  width: 100%;
`;

export default ConstructionSiteContainer;
