import styled from "styled-components";
import moment from "moment";
import "moment-duration-format";
import {
  adminDeleteConstructionSite,
  exportConstructionSiteToExcel,
} from "../services/apiRouterService";
import cogoToast from "cogo-toast";
const EmployeeDetail = ({ constructionSite, refetch, qrCodes, dates }) => {
  const deleteConstructionSite = async (e, constructionSiteUuid) => {
    if (e) e.preventDefault();
    const { response, status } = await adminDeleteConstructionSite(
      constructionSiteUuid
    );
    if (status === 200) cogoToast?.success("Construction site deleted");
    else cogoToast?.success(response?.detail);
    refetch();
  };

  const exportToExcel = async (e) => {
    e.preventDefault();
    try {
      exportConstructionSiteToExcel(
        constructionSite.construction_site_uuid,
        dates
      );
    } catch {
      cogoToast.error("Something went wrong ");
    }
  };

  return (
    <Container>
      <Header>
        <h3>{constructionSite?.construction_site_name}</h3>
        <Button onClick={exportToExcel}>Export to excel</Button>
      </Header>
      <Punches>
        {constructionSite?.punches?.length === 0 ? (
          <p>No punches yet</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Employee</th>
                <th>Start punch date</th>
                <th>Start punch time</th>
                <th>End punch date</th>
                <th>End punch time</th>
                <th>Time worked</th>
              </tr>
            </thead>
            <tbody>
              {constructionSite?.punches?.map((punch, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {punch?.employee?.first_name} {punch?.employee?.last_name}
                  </td>
                  <td>
                    {punch?.start_punch
                      ? moment(punch?.start_punch?.time).format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td>
                    {punch?.start_punch
                      ? moment(punch?.start_punch?.time).format("HH:mm:s")
                      : "-"}
                  </td>
                  <td>
                    {punch?.end_punch
                      ? moment(punch?.end_punch?.time).format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td>
                    {punch?.end_punch
                      ? moment(punch?.end_punch?.time).format("HH:mm:s")
                      : "-"}
                  </td>
                  <td>
                    {punch?.hours_worked
                      ? moment
                          .duration(punch?.hours_worked, "h")
                          .format("HH:mm:ss")
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Punches>
      {qrCodes?.constructionSites?.[
        constructionSite?.construction_site_uuid
      ] && (
        <img
          src={
            qrCodes?.constructionSites?.[
              constructionSite?.construction_site_uuid
            ]
          }
          alt="qr-code"
        />
      )}
      <Delete
        onClick={(e) =>
          deleteConstructionSite(e, constructionSite?.construction_site_uuid)
        }
      >
        Delete construction site
      </Delete>
    </Container>
  );
};

const Button = styled.button`
  padding: 2rem 1rem;
  margin-left: 2rem;
`;

const Container = styled.div`
  h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  img {
    max-width: 10rem;
  }
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Delete = styled.button``;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Punches = styled.div`
  font-size: 1.2rem;
  width: 100%;

  table {
    text-align: center;
    width: 100%;

    th,
    td {
      padding: 1rem;
    }
  }
`;

export default EmployeeDetail;
