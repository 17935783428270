import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locale/en.json';
import nl from './locale/nl.json';
import fr from './locale/fr.json';
import it from './locale/it.json';
import ua from './locale/ua.json';
import pt from './locale/pt.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en,
      nl,
      fr,
      it,
      ua,
      pt,
    },
    whitelist: ['en', 'nl', 'fr', 'ua', 'it', 'pt'],
    ns: ['translations'],
    defaultNS: 'translations',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    parseMissingKeyHandler: (key) => {
      if (process?.env?.NODE_ENV === 'development') {
        console.warn(
          `React-i18next key "${key}" not found in translation files.`,
        );
      }
      return key;
    },
  });

console.log(i18n.language);

export default i18n;
