import styled from "styled-components";
import LoadingSpinner from "../components/LoadingSpinner";
import EmployeeDetail from "../components/EmployeeDetail";
import cogoToast from "cogo-toast";
import { exportEmployeesToExcel } from "../services/apiRouterService";

const EmployeeContainer = ({
  isLoading,
  employees,
  refetch,
  qrCodes,
  dates,
}) => {
  const exportToExcel = async (e) => {
    e.preventDefault();
    try {
      exportEmployeesToExcel(dates);
    } catch {
      cogoToast.error("Something went wrong ");
    }
  };

  return (
    <Container>
      <Header>
        <h2>Employee container</h2>{" "}
        <Button onClick={exportToExcel}>Export to excel</Button>
      </Header>
      <Employees>
        {isLoading ? (
          <SpinnerContainer>
            <LoadingSpinner size={30} />
          </SpinnerContainer>
        ) : (
          employees?.map((employee) => (
            <EmployeeDetail
              refetch={refetch}
              dates={dates}
              qrCodes={qrCodes}
              key={employee?.employee_id}
              employee={employee}
            />
          ))
        )}
      </Employees>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 2rem 1rem;
  margin-left: 2rem;
`;
const Employees = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  margin: -1rem;
  & > div {
    margin: 1rem;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    margin: auto;
    position: static;
  }
`;

const Container = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
`;

export default EmployeeContainer;
