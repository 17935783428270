import { useParams } from "react-router";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { verifyEmployee } from "../services/apiRouterService";

import LoadingSpinner from "../components/LoadingSpinner";
import ErrorComponent from "../components/ErrorComponent";
import Header from "../components/Header";

const PunchClock = () => {
  const { t } = useTranslation();
  const { employee_uuid } = useParams();
  const { isLoading, data } = useQuery(employee_uuid, () =>
    verifyEmployee(employee_uuid)
  );

  if (isLoading)
    return (
      <LoadingContainer {...{ showTop: false }}>
        <LoadingSpinner isAbsolute />
      </LoadingContainer>
    );

  if (data?.status === 200) {
    return (
      <Container>
        <Header>{t("Punch clock")}</Header>
        <h1>
          {t("Employee")}: {data?.response?.employee_fname}{" "}
          {data?.response?.employee_lname}
        </h1>
      </Container>
    );
  } else {
    return <ErrorComponent errors={data} />;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem;
  font-size: 1.5rem;
  padding-top: 6.5rem;
  line-height: 1.5;
`;

const LoadingContainer = styled.div`
  width: 100%;
`;

export default PunchClock;
