import React, { forwardRef } from "react";
import { node, string, object } from "prop-types";
import styled from "styled-components";
import Error, { getBorderColor } from "./Error";

const TextInput = forwardRef(
  ({ className, children, disabled, error, warning, ...otherProps }, ref) => (
    <Container className={className}>
      <StyledLabel disabled={disabled}>
        {children}
        <StyledInput
          ref={ref}
          disabled={disabled}
          error={error}
          warning={warning}
          {...otherProps}
        />
      </StyledLabel>
      <Error error={error} warning={warning} />
    </Container>
  )
);

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 9rem;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: ${(props) => (props.disabled ? "#AEAEAE" : "#5B5550")};
  line-height: 1rem;
`;

const StyledInput = styled.input`
  background-color: ${(props) => (props.disabled ? "#F0F1F3" : "white")};
  width: 100%;
  border: 0.1rem solid;
  border-color: ${({ error, touched, theme, warning }) =>
    getBorderColor(error, touched, theme, warning)};
  border-radius: 0.3rem;
  height: 4rem;
  font-size: 1.6rem;
  margin-top: 1rem;
  box-sizing: border-box;
  padding-right: 0.7rem;
  padding-left: 0.7rem;

  &:focus {
    outline: none;
    border-color: darkgray;
  }

  &::placeholder {
    color: gray;
  }
`;

TextInput.displayName = "TextInput";

TextInput.defaultProps = {
  error: "",
  warning: "",
  className: "",
  otherProps: {},
};

TextInput.propTypes = {
  /** Beeing able to use it in Styled Components */
  className: string,
  /** label above the input */
  children: node,
  /** name of input and label */
  /** string with errormessage */
  error: string,
  /** string with warningmessage */
  warning: string,
  /** Adds extra props to the element */
  otherProps: object,
};

StyledInput.displayName = "StyledInput";
TextInput.displayName = "TextInput";

export default TextInput;
