import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRouteHoc from "../hoc/PrivateRouteHoc";
import Admin from "../pages/Admin";
import Login from "../pages/Admin/Login";
import ConstructionSitesDetail from "../pages/Admin/ConstructionSitesDetail";
import EmployeeDetail from "../pages/Admin/EmployeeDetail";

const prefix = "/admin";

const AdminPanelRouter = () => (
  <Router>
    <Switch>
      <PrivateRouteHoc path={`${prefix}/`} component={Admin} exact />
      <PrivateRouteHoc
        path={`${prefix}/construction-sites/:construction_site_uuid`}
        component={ConstructionSitesDetail}
        exact
      />
      <PrivateRouteHoc
        path={`${prefix}/employees/:employee_uuid`}
        component={EmployeeDetail}
        exact
      />
      <Route path={`${prefix}/login`} component={Login} exact />
      <PrivateRouteHoc path={`${prefix}/test`}>
        <h1>Test</h1>
      </PrivateRouteHoc>
    </Switch>
  </Router>
);

export default AdminPanelRouter;
