import { request } from "./httpService.js";
const domain = `https://api.amant.dev`;
// const domain = `http://localhost:5000`;
export async function verifyEmployee(employee_uuid) {
  const path = `${domain}/employee/${employee_uuid}`;
  return request(path, "GET");
}

export async function verifyAuthenticatedEmployee() {
  const path = `${domain}/employee`;
  return request(path, "GET");
}

export async function checkEmployee(type, payload) {
  const path = `${domain}/employee/check-in/${type}`;
  return request(path, "POST", payload);
}

export async function getMeAdmin() {
  const path = `${domain}/admin/me`;
  return request(path, "GET");
}

export async function exportUserToExcel(user_uuid) {
  const path = `${domain}/admin/excel/employees/${user_uuid}`;
  return request(path, "GET");
}

async function downloadUsingFetch(response) {
  let header = response.headers.get("Content-Disposition");
  if (!header) header = response.headers.get("content-disposition");
  const parts = header?.split(";");
  console.log({ parts });
  const fileName =
    parts?.[1]?.split("=")?.[1]?.replaceAll('"', "") || "excelfile.xlsx";
  const excelBlob = await response.blob();
  const excelURL = URL.createObjectURL(excelBlob);
  const anchor = document.createElement("a");
  anchor.href = excelURL;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(excelURL);
}

export async function exportConstructionSiteToExcel(
  construction_site_uuid,
  values
) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/excel/construction-sites/${construction_site_uuid}?${new URLSearchParams(
    payload
  ).toString()}`;
  const { response, status } = await request(path, "GET");
  if (status === 200) {
    downloadUsingFetch(response);
  }
  return [response, status];
}

export async function exportConstructionSitesToExcel(values) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/excel/construction-sites?${new URLSearchParams(
    payload
  ).toString()}`;
  const { response, status } = await request(path, "GET");
  if (status === 200) {
    downloadUsingFetch(response);
  }
  return [response, status];
}

export async function exportEmployeeToExcel(employee_uuid, values) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/excel/employees/${employee_uuid}?${new URLSearchParams(
    payload
  ).toString()}`;
  const { response, status } = await request(path, "GET");
  if (status === 200) {
    downloadUsingFetch(response);
  }
  return [response, status];
}

export async function exportEmployeesToExcel(values) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/excel/employees?${new URLSearchParams(
    payload
  ).toString()}`;
  const { response, status } = await request(path, "GET");
  if (status === 200) {
    downloadUsingFetch(response);
  }
  return [response, status];
}

export async function getEmployeesAdmin(values) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/employees?${new URLSearchParams(
    payload
  ).toString()}`;
  return request(path, "GET");
}

export async function getConstructionSitesAdmin(values) {
  const payload = {
    ...(values?.start ? { start: values.start } : {}),
    ...(values?.end ? { end: values.end } : {}),
  };
  const path = `${domain}/admin/construction-sites?${new URLSearchParams(
    payload
  ).toString()}`;
  return request(path, "GET");
}

export async function adminLogin(payload) {
  const path = `${domain}/admin/login`;
  return request(path, "post", payload);
}

export async function adminAddEmployee(payload) {
  const path = `${domain}/admin/employees`;
  return request(path, "post", payload);
}
export async function adminAddConstructionSite(payload) {
  const path = `${domain}/admin/construction-sites`;
  return request(path, "post", payload);
}

export async function adminDeleteEmployee(employeeUuid) {
  const path = `${domain}/admin/employees/${employeeUuid}`;
  return request(path, "delete");
}

export async function adminDeleteConstructionSite(constructionSiteUuid) {
  const path = `${domain}/admin/construction-sites/${constructionSiteUuid}`;
  return request(path, "delete");
}

export async function getConstructionSiteInformation(construction_site_uuid) {
  const path = `${domain}/construction-sites/${construction_site_uuid}`;
  return request(path, "GET");
}
